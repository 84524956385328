.box {
    height: 100%;
}

.theme-btn {
    opacity: 0.75;
    position: absolute;
    right: -6px;
    top: -35px;
    cursor: pointer;
}

.theme-btn:hover {
    opacity: 1;
}

.theme-btn a .dark-icon {
    transform: scale(0.55);
}

.theme-btn a .light-icon {
    display: none;
    transform: scale(0.54);
}

body {
    background-color: rgb(21, 32, 43);
}

.notification {
    background-color: #018470;
}

.footer {
    background-color: #15202b;
}

.box {
    background-color: #192734;
}

.box>p {
    color: #e6e8f1;
}

.title {
    color: #e6e8f1;
}

.subtitle {
    color: #a0a2af;
}